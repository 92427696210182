import store from "@/store";
//版本信息, 版本更新，清除缓存
const packageVersion = require("../../package.json").version;
const localVersion = localStorage.getItem("IST_VERSION");
if (
  localVersion === null ||
  localVersion === undefined ||
  localVersion === ""
) {
  localStorage.setItem("IST_VERSION", packageVersion);
} else {
  if (localVersion !== packageVersion) {
    localStorage.setItem("IST_VERSION", packageVersion);
    localStorage.removeItem("IST_USER_AGENT");
  }
}
const getQueryString = (name) => {
  const url_string = window.location.href; // window.location.href
  const url = new URL(url_string);
  return url.searchParams.get(name);
};
//初始化
let browserAgent = "h5-web"; // h5页面
const isMobile = /Mobile|Android|iPhone/.test(navigator.userAgent);
if (isMobile) {
  const isWechat =
    navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
  const isAlipay = navigator.userAgent.toLowerCase().indexOf("alipay") !== -1;
  if (isWechat) {
    browserAgent = "wechat-web";
  }
  if (isAlipay) {
    browserAgent = "alipay-web";
  }
} else {
  // PC
  browserAgent = "pc-web";
}
const istCode = getQueryString("ist_code");
let userAgent = JSON.parse(localStorage.getItem("IST_USER_AGENT"));

let currentLang = "zh-CN";
if (userAgent) {
  currentLang = userAgent.lang;
}
if (navigator.language.toLowerCase().includes("zh")) {
  currentLang = "zh-CN";
} else {
  currentLang = "en-US";
}

let userAgentParams = {
  browserAgent: browserAgent,
  istCode: "",
  openid: "",
  lang: currentLang,
};
if (userAgent) {
  userAgentParams.istCode = userAgent.istCode;
  userAgentParams.openid = userAgent.openid;
  userAgentParams.lang = userAgent.lang || currentLang;
}
if (istCode) {
  // 设置URL参数
  userAgentParams.istCode = istCode;
}

store.commit("SET_IST_USER_AGENT", userAgentParams);
