import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import request from "@/utils/request";
import { showDialog } from "vant";
const routes = [
  {
    path: "/",
    name: "layout",
    redirect: "/subscribe",
    component: () => import("../views/layout/index"),
    children: [
      {
        path: "/subscribe",
        name: "subscribe",
        component: () => import("../views/subscribe/index"),
      },
      {
        path: "/mine",
        name: "mine",
        component: () => import("../views/subscribe/mine"),
      },
    ],
  },
  {
    path: "/h5",
    name: "h5",
    component: () => import("../views/h5/index"),
  },
  {
    path: "/webview",
    name: "webview",
    component: () => import("../views/webview/index"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let userAgent = JSON.parse(localStorage.getItem("IST_USER_AGENT"));
  if (to.fullPath.includes("/h5") || to.fullPath.includes("/webview")) {
    if (userAgent.browserAgent !== "wechat-web") {
      next();
      return;
    }
  }
  //微信浏览器, 重定向，获取用户OPENID
  if (userAgent.browserAgent === "wechat-web") {
    // 判断缓存是否存在openid
    if (userAgent.openid === "" || userAgent.openid === undefined) {
      if (to.query.openid === undefined || to.query.openid === "") {
        // 不存在openid, 向后端请求重定向URL获取用户openid
        const uri = "https://api.global.iscett.com/app/pay/wechat/config";
        const response = await request.post(uri, {
          redirect: window.location.href,
          istCode: userAgent.istCode,
          platform: to.fullPath.includes("/h5") ? "h5" : "",
        });
        if (response.errCode === 1) {
          window.location.href = response.data.redirect;
        } else {
          showDialog({
            message: i18n.global.t("message.scan_qr_code"),
            confirmButtonDisabled: true,
          });
        }
      } else {
        const openid = to.query.openid;
        userAgent.openid = openid;
        store.commit("SET_IST_USER_AGENT", userAgent);
      }
    }
  }
  if (userAgent.browserAgent === "wechat-web" && to.fullPath.includes("/h5")) {
    next();
    return;
  }
  const currentQuery = { ...to.query };
  const objLength = Object.keys(currentQuery).length;
  if (objLength > 1) {
    for (let key in currentQuery) {
      if (key !== "ist_code") {
        delete currentQuery[key];
      }
    }
    next({ path: to.path, query: currentQuery });
  } else {
    next();
  }
});

export default router;
