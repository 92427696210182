import axios from "axios";
import { showLoadingToast, closeToast } from "vant";
let agentLang = "en-US";
try {
  const userAgent = JSON.parse(localStorage.getItem("IST_USER_AGENT"));
  if (userAgent.lang.toLowerCase().includes("zh")) {
    agentLang = "zh-CN";
  }
} catch (e) {}
const service = axios.create({
  timeout: 30000, // 请求超时,
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Accept-Lang": agentLang,
  },
});
const startLoading = () => {
  showLoadingToast({
    message: "loading...",
    forbidClick: true,
    overlay: true,
  });
};

const endLoading = () => {
  closeToast();
};
//请求拦截器
service.interceptors.request.use(
  (config) => {
    if (!config.url.includes("open/sub/pay-status")) {
      // 轮询不作加载事件
      startLoading();
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

//响应拦截器
/**
 * 可设置401,403等
 */
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      endLoading();
      return Promise.resolve(response.data);
    }
    return Promise.reject(response);
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// get请求
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
// post请求
export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    service
      .post(url, JSON.stringify(params))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  get,
  post,
};
