import { createStore } from "vuex";

export default createStore({
  state: {
    SELECT_ITEM: null,
    USER_AGENT: {
      browserAgent: "",
      istCode: "",
      openid: "",
    },
    ISALIPAY: false, // 做流量套餐不要支付宝支付按钮，后期支付宝做好会删掉
  },
  getters: {
    GET_IST_USER_AGENT: (state) => {
      return JSON.parse(localStorage.getItem("IST_USER_AGENT"));
    },
  },
  mutations: {
    SET_IST_USER_AGENT: (state, data) => {
      state.USER_AGENT = data;
      localStorage.setItem("IST_USER_AGENT", JSON.stringify(data));
    },
  },
  actions: {},
  modules: {},
});
