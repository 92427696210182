import { createI18n } from "vue-i18n";

let userAgent = JSON.parse(localStorage.getItem("IST_USER_AGENT"));
const i18n = createI18n({
  locale: userAgent.lang,
  globalInjection: true,
  fallbackLocale: "en-US",
  messages: {
    "zh-CN": require("./zh-CN.json"),
    "en-US": require("./en-US.json"),
    "zh-TW": require("./zh-TW.json"),
  },
});

export default i18n;
