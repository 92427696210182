import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@vant/touch-emulator';
import '@/assets/css/base.css'
import 'vant/lib/index.css';
import './utils/portal.js'
import i18n from './locales/i18n'
createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .mount('#app')
